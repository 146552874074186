import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, newDatas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRowsHead = [
          [
            { text: vm.$t('org_pro_division.division') + ' : ' + (vm.search.division_id ? ((i18n.locale === 'en') ? vm.searchResult.division_name : vm.searchResult.division_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('org_pro_district.district') + ' : ' + (vm.search.district_id ? ((i18n.locale === 'en') ? vm.searchResult.district_name : vm.searchResult.district_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('org_pro_upazilla.upazilla') + ' : ' + (vm.search.upazilla_id ? ((i18n.locale === 'en') ? vm.searchResult.upazilla_name : vm.searchResult.upazilla_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('dae_subsidy.subsidy_circular') + ' : ' + (vm.search.circular_id ? ((i18n.locale === 'en') ? vm.searchResult.circular_name : vm.searchResult.circular_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        newDatas.forEach((info2, index) => {
          if (info2.upazilla_id) {
            const upname = (i18n.locale === 'bn') ? info2.upazilla_name_bn : info2.upazilla_name
            pdfContent.push({ text: vm.$t('org_pro_upazilla.upazilla') + ':' + upname, style: 'header2', alignment: 'left' })
          }
          if (info2.city_corporation_id) {
            const corporation = (i18n.locale === 'bn') ? info2.corporation_name_bn : info2.corporation_name
            pdfContent.push({ text: vm.$t('org_pro.city_corporation') + ':' + corporation, style: 'header2', alignment: 'left' })
          }
          const allRows = [
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('dae_grant_allocation_distribution.farmer_name'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('dae_grant_allocation_distribution.farmer_nid'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('dae_grant_allocation_distribution.farmer_mobile_number'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('globalTrans.union'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('dae_config.instrument_name'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('dae_subsidy.brand_name'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('dae_subsidy.model'), style: 'th', alignment: 'center', bold: true }
            ]
          ]
          info2.farmer_info.forEach((info, index2) => {
            allRows.push([
              { text: vm.$n(index2 + 1), alignment: 'center', style: 'td' },
              { text: (i18n.locale === 'bn') ? info.name_bn : info.name, alignment: 'center', style: 'td' },
              { text: vm.$n(info.nid_no, { useGrouping: false }), alignment: 'center', style: 'td' },
              { text: info.mobile_no === null ? ' ' : (i18n.locale === 'en' ? '0' : '০') + '' + vm.$n(info.mobile_no, { useGrouping: false }), alignment: 'center' },
              { text: (i18n.locale === 'bn') ? info.union_name_bn : info.union_name, alignment: 'center', style: 'td' },
              { text: (i18n.locale === 'bn') ? info.instrument_name_bn : info.instrument_name, alignment: 'center', style: 'td' },
              { text: (i18n.locale === 'bn') ? info.brand_name_bn : info.brand_name, alignment: 'center', style: 'td' },
              { text: info.model, alignment: 'center', style: 'td' }
            ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '15%', '15%', '15%', '10%', '10%', '10%', '20%'],
            body: allRows
          }
        })
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A3',
        pageOrientation: 'Landcape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
