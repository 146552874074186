<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('dae_subsidy.subsidy_distribution_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_division.division')"
                    label-for="division_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.division_id"
                        :options="divisionList"
                        id="division_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="3"
                    :label="$t('org_pro_district.district')"
                    label-for="district_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.district_id"
                        :options="districtList"
                        id="district_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_upazilla.upazilla')"
                    label-for="upazilla_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.upazilla_id"
                        :options="upazilaList"
                        id="upazilla_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Fiscal Year" vid="fiscal_year" rules="">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="fiscal_year"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('dae_subsidy.fiscal_year')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year"
                          :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Circular" vid="circular_id">
                      <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="circular_id"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                          {{ $t('dae_subsidy.subsidy_circular') }}
                          </template>
                          <b-form-select
                          plain
                          v-model="search.circular_id"
                          :options="circularList"
                          id="circular_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('dae_subsidy.subsidy_distribution_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport">
                            <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                          </b-button>
                          <!-- <export-excel
                            class="btn btn-success mr-2"
                            :data="excelData"
                            :title="headerValue"
                            worksheet="Report Sheet"
                            :default-value="headerExcelDefault"
                            name="approved-beneficiary-report.xls">
                            {{ $t('globalTrans.export_excel') }}
                          </export-excel> -->
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="2">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('dae_subsidy.subsidy_distribution_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                <div class="text-black">
                                <b-row>
                                  <b-col md="3"><strong>{{ $t('org_pro_division.division') }}: </strong>{{ search.division_id ? ($i18n.locale === 'en' ? searchResult.division_name : searchResult.division_name_bn ) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('org_pro_district.district') }}: </strong>{{ search.district_id  ?  ($i18n.locale === 'en' ? searchResult.district_name : searchResult.district_name_bn) : $t('globalTrans.all') }}</b-col>
                                  <b-col md="3"><strong>{{ $t('org_pro_upazilla.upazilla') }}: </strong>{{ search.upazilla_id ? ($i18n.locale === 'en' ? searchResult.upazilla_name : searchResult.upazilla_name_bn) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('dae_subsidy.subsidy_circular') }}: </strong>{{ search.circular_id ? ($i18n.locale === 'en' ? searchResult.circular_name : searchResult.circular_name_bn) : $t('globalTrans.all')  }}</b-col>
                                </b-row>
                              </div>
                              <template v-if="datas.length">
                                  <b-row v-for="(item, parentIndex) in datas" :key="parentIndex">
                                    <b-col>
                                        <span v-if="item.upazilla_id"><h4> {{ $t('org_pro_upazilla.upazilla') }} : {{ $i18n.locale === 'en' ? item.upazilla_name : item.upazilla_name_bn }}</h4></span>
                                        <span v-if="item.city_corporation_id"><h4> {{ $t('org_pro.city_corporation') }} : {{ $i18n.locale === 'en' ? item.corporation_name : item.corporation_name_bn }}</h4></span>
                                      <b-table-simple class="tg mt-3" bordered hover row-hovered small caption-top responsive show-empty>
                                        <b-thead>
                                          <b-tr>
                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_nid') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_mobile_number') }}</b-th>
                                            <b-th class="text-center">{{ $t('globalTrans.union') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_config.instrument_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.brand_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.model') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in item.farmer_info" :key="index">
                                          <b-td class="text-center">{{  $n(index + 1) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.name_bn : info.name }}</b-td>
                                          <b-td class="text-center">{{ $n(info.nid_no,{ useGrouping: false }) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale === 'en' ? '0' : '০') }}{{ $n(info.mobile_no,{ useGrouping: false }) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.union_name_bn : info.union_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.instrument_name_bn : info.instrument_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.brand_name_bn : info.brand_name }}</b-td>
                                          <b-td class="text-center">{{ info.model }}</b-td>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </template>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { distributionReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    created () {
    },
    data () {
        return {
          search: {
            division_id: 0,
            district_id: 0,
            upazilla_id: 0,
            circular_id: 0,
            fiscal_year_id: 0
          },
          searchResult: {
            division_name: '',
            division_name_bn: '',
            district_name: '',
            district_name_bn: '',
            upazilla_name: '',
            upazilla_name_bn: '',
            circular_name: '',
            circular_name_bn: '',
            fiscal_year: '',
            fiscal_year_bn: ''
          },
          incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
          datas: [],
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          loading: false,
          showData: false,
          subsidyList: [],
          upazilaList: [],
          districtList: [],
          unionList: [],
          circularList: []
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
        this.headerDataExcel()
    },
    computed: {
        // circularList: function () {
        //   return this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.type === 1)
        // },
        divisionList: function () {
          return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        headerValue: function () {
          const headerVal = []
          if (this.$i18n.locale === 'en') {
              headerVal[0] = this.headerExcelDefault.orgName
              headerVal[1] = this.headerExcelDefault.address
              headerVal[2] = this.$t('BarcReport.grant_application_status_report')
              headerVal[3] = this.$t('elearning_config.fiscal_year') + ':  ' + this.searchResult.fiscal_year + '; ' + ' ' + this.$t('dae_config.season_name') + ': ' + this.searchResult.season_name
              headerVal[4] = this.$t('bsri_config.subsidy_type') + ':  ' + this.searchResult.subsidy_type_name + '; ' + ' ' + this.$t('bsri_config.subsidy_name') + ': ' + this.searchResult.subsidy_name
            } else {
              headerVal[0] = this.headerExcelDefault.orgNameBn
              headerVal[1] = this.headerExcelDefault.address_bn
              headerVal[2] = this.$t('BarcReport.grant_application_status_report')
              headerVal[3] = this.$t('elearning_config.fiscal_year') + ':  ' + this.searchResult.fiscal_year_bn + '; ' + ' ' + this.$t('dae_config.season_name') + ': ' + this.searchResult.season_name_bn
              headerVal[4] = this.$t('bsri_config.subsidy_type') + ':  ' + this.searchResult.subsidy_type_name_bn + '; ' + ' ' + this.$t('bsri_config.subsidy_name') + ': ' + this.searchResult.subsidy_name_bn
            }
            return headerVal
          },
        excelData: function () {
          const listData = this.datas
          var serial = 0
          const listContractor = listData.map(keyItem2 => {
            return keyItem2.farmer_info.map(keyItem => {
            serial++
             if (this.$i18n.locale === 'en') {
                return {
                  'sl ': serial,
                  'Farmer Name': keyItem.name,
                  'Farmer NID': keyItem.nid_no,
                  'Farmer Mobile Number': keyItem.mobile_no,
                  'Union ': keyItem.union_name,
                  'Instrument Name ': keyItem.instrument_name,
                  'Brand Name ': keyItem.brand_name,
                  'Model ': keyItem.model
                }
              } else {
                return {
                  'ক্রমিক সংখ্যা': this.$n(serial),
                  'কৃষকের নাম ': keyItem.name_bn,
                  'কৃষকের এনআইডি': keyItem.nid_no,
                  'কৃষকের মোবাইল নম্বর': keyItem.mobile_no,
                  'ইউনিয়ন ': keyItem.union_name_bnme,
                  'যন্ত্র ': keyItem.instrument_name_bn,
                  'পরিচিতিমুলক নাম': keyItem.brand_name_bn,
                  'মডেল ': keyItem.upazilla_name_bn
                }
              }
            })
          })
          return listContractor
        },
      fiscalYearList () {
        return this.$store.state.commonObj.fiscalYearList
      }
    },
    watch: {
      'search.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
      },
      'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
      },
      'search.upazilla_id': function (newVal, oldVal) {
        this.unionList = this.getUnionList(newVal)
      },
      'search.fiscal_year_id': function (newValue, oldVal) {
        this.circularList = this.getCircularList()
      }
    },
    methods: {
      getDistrictList (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

        if (divisionId) {
            return districtList.filter(district => district.division_id === divisionId)
        }

        return districtList
      },
      getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

          if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
          }

        return upazilaList
      },
      getUnionList (upazillaId = null) {
        const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
          if (upazillaId) {
            return unionList.filter(union => union.upazilla_id === upazillaId)
          }
          return unionList
      },
      headerDataExcel () {
        RestApi.getData(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail/2').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 2)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
      },
      pdfExport () {
          const reportTitle = this.$t('dae_subsidy.subsidy_distribution_report')
          ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 2, reportTitle, this.datas, this, this.searchResult)
      },
      async searchData () {
      this.loading = true
      this.showData = true
      const params = Object.assign({}, this.search)
        await RestApi.getData(incentiveGrantServiceBaseUrl, distributionReport, params).then(response => {
          if (response.success) {
            if (response) {
              if (this.search.division_id) {
                const divisionObj = this.divisionList.find(divisionItem => divisionItem.value === this.search.division_id)
                this.searchResult.division_name = divisionObj !== undefined ? divisionObj.text_en : ''
                this.searchResult.division_name_bn = divisionObj !== undefined ? divisionObj.text_bn : ''
              }
              if (this.search.district_id) {
                const districtObj = this.$store.state.commonObj.districtList.find(districtItem => districtItem.value === this.search.district_id)
                this.searchResult.district_name = districtObj !== undefined ? districtObj.text_en : ''
                this.searchResult.district_name_bn = districtObj !== undefined ? districtObj.text_bn : ''
              }
              if (this.search.upazilla_id) {
                const upazillaObj = this.$store.state.commonObj.upazilaList.find(disItem => disItem.value === this.search.upazilla_id)
                this.searchResult.upazilla_name = upazillaObj !== undefined ? upazillaObj.text_en : ''
                this.searchResult.upazilla_name_bn = upazillaObj !== undefined ? upazillaObj.text_bn : ''
              }
              if (this.search.circular_id) {
                const circularObj = this.$store.state.incentiveGrant.commonObj.circularList.find(circularItem => circularItem.value === this.search.circular_id)
                this.searchResult.circular_name = circularObj !== undefined ? circularObj.text_en : ''
                this.searchResult.circular_name_bn = circularObj !== undefined ? circularObj.text_bn : ''
              }
              this.datas = this.relationData(response.data)
            }
          } else {
            this.datas = []
          }
        })
        this.loading = false
      },
      relationData (data) {
        const upazillaList = this.$store.state.commonObj.upazilaList
        const unionList = this.$store.state.commonObj.unionList
        const instrumentList = this.$store.state.incentiveGrant.commonObj.instrumentList
        const cityCorportionList = this.$store.state.commonObj.cityCorporationList
        const listData = data.map(item => {
          // const districtObject = districtStore.find(districtItem => districtItem.value === item.district_id)
          const upazillaObject = upazillaList.find(upazillaItem => upazillaItem.value === item.upazilla_id)
          const cityCorportionListObject = cityCorportionList.find(upazillaItem => upazillaItem.value === item.city_corporation_id)

          const upazillaData = {
            upazilla_name: upazillaObject !== undefined ? upazillaObject.text_en : ' ',
            upazilla_name_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ' '
          }
          const corporationData = {
            corporation_name: cityCorportionListObject !== undefined ? cityCorportionListObject.text_en : ' ',
            corporation_name_bn: cityCorportionListObject !== undefined ? cityCorportionListObject.text_bn : ' '
          }
          item.farmer_info = item.farmer_info.map(upazillInfoItem => {
            const unionObject = unionList.find(unionItem => unionItem.value === upazillInfoItem.union_id)
            const instrumentObject = instrumentList.find(instruItem => instruItem.value === upazillInfoItem.instrument_id)
            const unionData = {
              union_name: unionObject !== undefined ? unionObject.text_en : ' ',
              union_name_bn: unionObject !== undefined ? unionObject.text_bn : ' '
            }
            const instrumentData = {
              instrument_name: instrumentObject !== undefined ? instrumentObject.text_en : ' ',
              instrument_name_bn: instrumentObject !== undefined ? instrumentObject.text_bn : ' '
            }
            return Object.assign({}, upazillInfoItem, unionData, instrumentData)
          })

          return Object.assign({}, item, upazillaData, corporationData)
        })

        return listData
      },
      getStatus (status) {
        if (status === 2) {
            return this.$i18n.locale === 'bn' ? 'অনুমোদিত' : 'Approved'
        } else {
          return this.$i18n.locale === 'bn' ? 'প্রত্যাখ্যান' : 'Reject'
        }
      },
      getCircularList () {
        const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.fiscal_year_id === this.search.fiscal_year_id && item.type === 1)
        return circularList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text }
          }
        })
      }
    }
}
</script>
